<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>

    <b-card
      no-body
      m-0
    >
      <b-card-header>
        <b-card-title v-if="userData.user !== null">
          Xem bài {{ userData.user.hoten }}
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <app-collapse
          v-if="userData.success === true"
          accordion
          type="shadow"
        >
          <app-collapse-item
            v-for="item in userData.bai"
            :key="item.nhom"
            :title="`${item.nhom}: ${item.chu_de}` "
          >
            <div v-html="item.noi_dung" />
          </app-collapse-item>
        </app-collapse>
        <div v-else>
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="show"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              {{ userData.message }}
            </div>
          </b-alert>
        </div>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-1"
          variant="outline-success"
          :to="{name: 'khach-hang'}"
        >
          <feather-icon icon="ArrowLeftCircleIcon" />
          Quay lại
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCardHeader, BCardTitle, BCard, BButton, BCardBody, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '@/views/users/userStoreModule'
import { getUserData } from '@/auth/utils'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import router from '@/router'
import { heightFade } from '@core/directives/animations'

export default {
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  components: {
    AppCollapseItem,
    BCardBody,
    BAlert,
    BButton,
    AppCollapse,
    BCardHeader,
    BCardTitle,
    BCard,
  },
  data() {
    return {
      show: true,
    }
  },
  setup() {
    const blankUserData = {
      bai: [],
      user: null,
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const currentUser = getUserData()

    store.state.showBlock = true
    store.dispatch('app-user/xemBai', {
      user: router.currentRoute.params.id,
      auth: currentUser.auth_key,
      uid: currentUser.id,
      khach_hang: router.currentRoute.params.id,
    })
      .then(response => {
        userData.value = response.data
        store.state.showBlock = false
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
        store.state.showBlock = false
      })

    return {
      userData,
    }
  },
}
</script>

<style scoped>

</style>
